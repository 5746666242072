import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col } from "react-bootstrap";
import { ViberIcon, BaseChildPage, StyledLink } from "Components";
import { Device } from "Utils";

const AddressLine = styled.div`
  overflow: hidden;
  padding-left: 40px;

  @media (max-width: ${Device.desktopXL}) {
    padding-left: 0px;
  }
`;

const AddressIcon = styled.a.attrs({
  className: "rounded-circle",
})`
  background: ${(props) => props.theme.colors.grey};
  color: ${(props) => props.theme.colors.black};
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  float: left;
  margin-right: 14px;
  font-size: 22px;

  :hover {
    background: ${(props) => props.theme.colors.black};
    color: ${(props) => props.theme.colors.primaryYellow};
  }

  @media (hover: none), (hover: on-demand) {
    :hover {
      /* suppress hover effect on devices that don't support hover fully */
      background: ${(props) => props.theme.colors.grey};
      color: ${(props) => props.theme.colors.black};
    }
  }
`;

const AddressTitle = styled.h4`
  font-size: 18px;
  overflow: hidden;
  font-weight: 600;
`;

const AddressBlock = styled.div`
  overflow: hidden;
  margin-top: 5px;
`;

const StyledViberIcon = styled(ViberIcon)`
  margin-left: 3px;
`;

const Address = (props) => {
  const linkProps = props.href ? { href: props.href, target: "_blank" } : {};
  return (
    <AddressLine className="mb-4 d-flex align-items-center mb-2">
      <div className="flex-shrink-0">
        <AddressIcon {...linkProps}>
          <FontAwesomeIcon icon={props.icon} />
        </AddressIcon>
      </div>
      <div className="flex-grow-1">
        <AddressTitle>{props.title}</AddressTitle>
        <AddressBlock>{props.children}</AddressBlock>
      </div>
    </AddressLine>
  );
};

const Contacts = () => {
  return (
    <BaseChildPage title="Контакты">
      <Container fluid className="pb-4 pb-sm-2">
        <Row className="justify-content-center">
          <Col xl={4} lg={5}>
            <Address title="О нас" icon="fa-solid fa-user">
              <div>Частное унитарное предприятие "ТехноСваркаПлюс"</div>
              <div>УНП: 291885726</div>
            </Address>
            <Address
              title="Наш адрес"
              icon="fas fa-map-marker-alt"
              href="https://yandex.by/maps/-/CCU7aWXFwC"
            >
              <div>г. Брест ул. Фортечная, 63Б/5, 224024</div>
            </Address>
            <Address
              title="Электронный адрес"
              icon="fa-solid fa-envelope"
              href="mailto:business@svarka-brest.by"
            >
              <div>business@svarka-brest.by</div>
            </Address>
            <Address
              title="Контактный телефон"
              icon="fa-solid fa-phone"
              href="tel:+375298067909"
            >
              <StyledLink
                color="black"
                href="tel:+375298067909"
                target="_blank"
              >
                +375 (29) 806-79-09 (MTC)
              </StyledLink>
              <StyledViberIcon />
            </Address>
            <Address title="Время работы" icon="fa-solid fa-clock">
              <div>
                Понедельник - Воскресенье: <br /> 09.00 - 21.00.
              </div>
            </Address>
          </Col>
          <Col xl={6} lg={6}>
            <AddressTitle className="text-center mb-3">
              Схема проезда
            </AddressTitle>
            <iframe
              title="Схема проезда"
              className="w-100"
              src="https://yandex.ru/map-widget/v1/?z=12&ol=biz&oid=184960985006"
              width="560"
              height="400"
            ></iframe>
          </Col>
        </Row>
      </Container>
    </BaseChildPage>
  );
};

export default Contacts;
