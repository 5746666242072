import styled from "styled-components";
import { Container, Row, Col, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LinkContainer } from "react-router-bootstrap";

const SiteFooter = styled.footer`
  width: 100%;
  background-color: ${(props) => props.theme.colors.navbar};
  min-height: 250px;
  padding: 10px 0px 5px 0px;
  color: #fff;
`;

const SocialIcon = styled.a.attrs({
  className: "rounded-circle",
})`
  font-size: 18px;
  display: inline-block;
  background: ${(props) => props.theme.colors.socialIcon};
  color: ${(props) => props.theme.colors.white};
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  cursor: pointer;

  :hover {
    background: ${(props) => props.theme.colors.primaryYellow};
    color: ${(props) => props.theme.colors.white};
    text-decoration: none;
  }

  @media (hover: none), (hover: on-demand) {
    :hover {
      background: ${(props) => props.theme.colors.socialIcon};
      color: ${(props) => props.theme.colors.white};
      text-decoration: none;
    }
  }
`;

const NavLink = styled(Nav.Link)`
  color: ${(props) => props.theme.colors.white} !important;

  &.nav-link.active {
    color: ${(props) => props.theme.colors.primaryYellow} !important;
  }

  :hover {
    color: ${(props) => props.theme.colors.primaryYellow} !important;
  }

  @media (hover: none), (hover: on-demand) {
    :hover {
      color: ${(props) => props.theme.colors.white} !important;
    }
  }
`;

const BorderedRow = styled(Row)`
  border-bottom: 1px solid ${(props) => props.theme.colors.white} !important;
`;

const Footer = (props) => {
  const className = `footer ${props.className}`;
  return (
    <div className={className}>
      <SiteFooter>
        <Container className="text-white bottom_border">
          <BorderedRow className="pt-3">
            <Col sm={6} md={4}>
              <h5>ЧУП "ТехноСваркаПлюс"</h5>
              <p>
                Сварочные работы любой сложности в Бресте и Брестской области.
                Широкий выбор услуг. Гарантия качества и доступные цены.
                Собственное производство.
                <br />
                УНП: 291885726.
              </p>
              <ul className="list-inline mt-3">
                <li className="list-inline-item">
                  <SocialIcon
                    href="viber://chat?number=%2B375298067909"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon="fa-stack fa-brands fa-viber" />
                  </SocialIcon>
                </li>
                <li className="list-inline-item">
                  <SocialIcon
                    href="https://www.instagram.com/svarka_brest.by/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon="fa-stack fa-brands fa-instagram" />
                  </SocialIcon>
                </li>
                {/*
                <li className="list-inline-item">
                  <SocialIcon href="https://t.me/+375298067909" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon="fa-stack fa-brands fa-telegram" />
                  </SocialIcon>
                </li> */}
              </ul>
            </Col>
            <Col sm={6} md={4} className="mt-2 mt-sm-0">
              <div className="d-flex align-items-center mb-3">
                <div className="flex-shrink-0">
                  <SocialIcon
                    href="https://yandex.by/maps/-/CCU7aWXFwC"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon="fas fa-map-marker-alt" />
                  </SocialIcon>
                </div>
                <div className="flex-grow-1 ms-3">
                  г. Брест ул. Фортечная, 63Б/5, 224024
                </div>
              </div>
              <div className="d-flex align-items-center mb-3">
                <div className="flex-shrink-0">
                  <SocialIcon
                    href="tel:+375298067909"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon="fa-solid fa-phone" />
                  </SocialIcon>
                </div>
                <div className="flex-grow-1 ms-3">
                  +375 (29) 806-79-09 (MTC)
                </div>
              </div>
              <div className="d-flex align-items-center mb-3">
                <div className="flex-shrink-0">
                  <SocialIcon href="mailto:business@svarka-brest.by">
                    <FontAwesomeIcon icon="fa-solid fa-envelope" />
                  </SocialIcon>
                </div>
                <div className="flex-grow-1 ms-3">business@svarka-brest.by</div>
              </div>
            </Col>
            <Col sm={12} md={4} className="mt-2 mt-md-0">
              <Nav className="flex-md-column justify-content-center">
                <LinkContainer to="/">
                  <NavLink>Главная</NavLink>
                </LinkContainer>

                <LinkContainer to="/services">
                  <NavLink eventKey={1}>Услуги</NavLink>
                </LinkContainer>

                <LinkContainer to="/partnership">
                  <NavLink eventKey={2}>Cотрудничество</NavLink>
                </LinkContainer>

                <LinkContainer to="/contacts">
                  <NavLink eventKey={3}>Контакты </NavLink>
                </LinkContainer>
              </Nav>
            </Col>
            <p className="mt-2 mt-sm-0 text-white text-center">
              От вас идея - от нас результат!
            </p>
          </BorderedRow>
          <p className="text-white text-center">
            © 2024 ЧУП "ТехноСваркаПлюс". <br className="d-sm-none" /> Все права
            защищены.
          </p>
        </Container>
      </SiteFooter>
    </div>
  );
};

export default Footer;
